export const formField = [
    {
      name: "Username",
      type: "text",
    },
    {
      name: "Password",
      type: "password",
    },
  ];
  