import axios from "axios";
import React, { useState } from "react";
import { Button, Card, FloatingLabel, Form } from "react-bootstrap";
import { formField } from "./json";
import ReactDOM from "react-dom";
import AlertComponent from "../components/alert";

function Login() {
  const [error, setError] = useState({
    errorType: "",
    errorMessage: "",
    errorShow: "",
  });
  const [valueLogin, setValueLogin] = useState({
    Username: "",
    Password: "",
  });
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  axios.defaults.withCredentials = true


  const handleLogin = () => {
    setError({ ...error, errorShow: false });
    const formData = new FormData();
    formData.append("username", valueLogin.Username);
    formData.append("password", valueLogin.Password);
    axios
      .post("/db/access/", formData)
      .then((res) => {
        if (res.data === "OK") document.location.href = `/db/main/`;
        else
          setError({
            errorMessage: "Nome utente o password non corrette",
            errorShow: true,
            errorType: "danger",
          });
      })
      .catch((err) => {
        setError({
          errorMessage: err.message,
          errorType: "danger",
          errorShow: true,
        });
        console.log(err);
      });
  };

  return (
    <>
      <div
        className="pb-5 pt-3  px-4 bg-white shadow border-0"
        style={{ margin: "200px auto auto auto", borderRadius:"20px", width:"450px"}}
      >

          {/*<div className="d-flex flex-column">
              <img src="/static/img/logo-orizzontale.png" className="px-3 pt-3"/>
              <div className="d-flex px-3 w-100 flex-column align-items-end">
                  <div style={{borderBottom: "1px dotted grey" ,width: "70%", margin: "0px", marginBottom:"10px"}}> </div>
                      <p style={{color: "grey", fontSize: "0.8rem"}}>SERVIZI ONLINE PER I SOCI</p>
              </div>
          </div>*/}

        <div className="row">
          <div className={"col-12 w-100"} style={{height:"200px"}}>
            <img src="/static/img/ok.png" className="" style={{width:"100%", height:"100%", transform:"scale(2.5)", objectFit:"cover"}}/>
          </div>
        </div>

        <div className={"mb-3 px-4"}>
          <div className={""}>
            {formField.map((el, index) => (
              <Form.Group key={index} className="d-flex flex-column mb-4">
                <FloatingLabel label={el.name}>
                  <Form.Control
                    type={el.type}
                    placeholder={el.name}
                    style={{ height: "60px", fontSize: "26px" }}
                    name={el.name}
                    onChange={(e) =>
                      setValueLogin({
                        ...valueLogin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>
            ))}
          </div>
          <h6 className="text-center">
            <a
                style={{ textDecoration:"none", color:"black"}}
              href="#"
              onClick={() =>
                (document.location.href = "/accounts/password_reset")
              }
            >
              Ho dimenticato la password
            </a>
          </h6>
          <AlertComponent
            errorMessage={error.errorMessage}
            errorShow={error.errorShow}
            errorType={error.errorType}
          />
        </div>
        <div className="mt-5 border-0">
          <Button
            onClick={() => handleLogin()}
            style={{borderRadius:"10px"}}
            className="d-flex m-auto px-3 py-2 mb-3 btn-secondary"
          >
            Login
          </Button>
        </div>
      </div>
    </>
  );
}

ReactDOM.render(<Login />, document.getElementById("login"));
