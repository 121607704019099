import React from "react";
import { Alert } from "react-bootstrap";

function AlertComponent({ errorType, errorMessage, errorShow }) {
  return (
    errorShow && (
      <Alert className="d-block m-auto w-100 my-3" variant={errorType}>
        {errorMessage}
      </Alert>
    )
  );
}

export default AlertComponent;
